@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Noto Sans", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0.14);
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.background-radial-gradient {
    background-color: hsl(218, 41%, 15%);
    background-image: radial-gradient(650px circle at 0% 0%,
    hsl(218, 41%, 35%) 15%,
    hsl(218, 41%, 30%) 35%,
    hsl(218, 41%, 20%) 75%,
    hsl(218, 41%, 19%) 80%,
    transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
            hsl(218, 41%, 45%) 15%,
            hsl(218, 41%, 30%) 35%,
            hsl(218, 41%, 20%) 75%,
            hsl(218, 41%, 19%) 80%,
            transparent 100%);
}

@media (min-width: 992px) {
    .rotate-lg-6 {
        transform: rotate(6deg);
    }
}

/* These are the KEY styles - you can add them directly to any object you want in your project */
.fancy-border-radius {
    border-radius: 53% 47% 52% 48% / 36% 41% 59% 64%;
}

.image-styled {
    background-position: center;
    background-size: cover;
    -webkit-transition: background 1s;
    -moz-transition: background 1s;
    -o-transition: background 1s;
    transition: background 1s;
}

/* #Mega Menu Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.mega-menu {
    display: none;
    left: 0;
    position: absolute;
    text-align: left;
    width: 100%;
}



/* #hoverable Class Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.hoverable {
    position: static;
}

.hoverable > a:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
}

.hoverable:hover .mega-menu {
    display: block;
}


/* #toggle Class Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.toggleable > label:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
}

.toggle-input {
    display: none;
}
.toggle-input:not(checked) ~ .mega-menu {
    display: none;
}

.toggle-input:checked ~ .mega-menu {
    display: block;
}

.toggle-input:checked + label {
    color: white;
    background: #2c5282; /*@apply bg-blue-800 */
}

.toggle-input:checked ~ label:after {
    content: "\25B2";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
}